<!--
 * @Description: 卡套餐配置
 * @Author: ChenXueLin
 * @Date: 2021-10-19 11:17:06
 * @LastEditTime: 2022-07-15 15:56:55
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="simPackageId">
              <el-input
                v-model="searchForm.simPackageId"
                placeholder="SIM套餐"
                title="SIM套餐"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-add_line"
            title="创建卡套餐"
            @click="addPackage('add')"
          ></i>
          <i
            class="e6-icon-clear_line"
            title="删除卡套餐"
            @click="deletePackage"
          ></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="handleEnable(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="停用"
            @click="handleEnable(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 创建/编辑卡套餐弹框  start -->
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible.sync="addVisible"
      width="620px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="addLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addForm"
        label-width="130px"
        :model="addForm"
        :rules="addRules"
      >
        <el-form-item label="套餐名称" prop="simPackageName">
          <el-input
            v-model.trim="addForm.simPackageName"
            placeholder="套餐名称"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐每月流量（M）" prop="monthlyFlow">
          <el-input
            v-model="addForm.monthlyFlow"
            placeholder="套餐每月流量（M）"
            maxlength="9"
          ></el-input>
        </el-form-item>
        <el-form-item label="月租费（元）" prop="monthlyFee">
          <el-input
            v-model="addForm.monthlyFee"
            placeholder="月租费（元）"
            maxlength="9"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 创建/编辑卡套餐弹框  end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  templateList,
  deleteTemplate,
  enableTemplate,
  disenableTemplate,
  createTemplate,
  updateTemplate
} from "@/api";
import { printError } from "@/utils/util";
import { greaterThan0NumberValid } from "@/utils/validate";
export default {
  name: "simPackage",
  components: {},
  data() {
    return {
      total: 0,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      searchForm: {
        createdBy: "", //创建人
        simPackageId: "", //sim卡套餐
        createTime: [], //创建时间
        createStartTime: "", //创建开始时间
        createEndTime: "", //创建结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending" // 默认降序
      },
      columnData: [
        {
          fieldName: "simPackageName",
          display: true,
          fieldLabel: "SIM卡套餐",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "monthlyFlow",
          display: true,
          fieldLabel: "套餐每月流量（M）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "monthlyFee",
          display: true,
          fieldLabel: "月租费（元）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isEnable",
          display: true,
          fieldLabel: "状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [], //选中的数据
      /*****创建套餐  start */
      addVisible: false,
      dialogTitle: "创建卡套餐",
      addForm: {
        monthlyFee: "", //月租费
        monthlyFlow: "", //套餐每月流量
        simPackageName: "" //SIM卡套餐
      },
      addLoading: false,
      queryListAPI: templateList
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {
    addRules() {
      // const numberValidator = new NumberValid().validator();
      return {
        simPackageName: [
          {
            required: true,
            message: "请输入套餐名称",
            trigger: ["blur", "change"]
          }
        ],
        monthlyFee: [
          {
            required: true,
            message: "请输入月租费",
            trigger: ["blur", "change"]
          },
          {
            validator: greaterThan0NumberValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        monthlyFlow: [
          {
            required: true,
            message: "请输入套餐每月流量",
            trigger: ["blur"]
          },
          {
            validator: greaterThan0NumberValid.validator(),
            trigger: ["blur", "change"]
          }
        ]
      };
    }
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createStartTime = val[0] || "";
        this.searchForm.createEndTime = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPackageId;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addPackage("edit", row);
      }
    },
    //删除卡套餐
    deletePackage() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        this.$confirm("是否确认删除所选卡套餐?", "删除卡套餐", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deletePackageReq();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
    },
    //删除卡套餐请求
    async deletePackageReq() {
      try {
        this.loading = true;
        let res = await deleteTemplate({
          list: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击启用、停用
    handleEnable(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      }
      let confirmTitleKeyWord = type == 1 ? "启用" : "停用";
      this.$confirm(
        `是否确认${confirmTitleKeyWord}所选卡套餐?`,
        `${confirmTitleKeyWord}卡套餐`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          if (type == 1) {
            this.enableTemplate();
          } else {
            this.disenableTemplate();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${confirmTitleKeyWord}`
          });
        });
    },
    //启用卡套餐请求
    async enableTemplate() {
      try {
        this.loading = true;
        let res = await enableTemplate({
          list: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //停用卡套餐请求
    async disenableTemplate() {
      try {
        this.loading = true;
        let res = await disenableTemplate({
          list: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("停用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /*******创建/编辑卡套餐*************/
    //创建卡套餐
    addPackage(type, row) {
      this.addVisible = true;
      this.type = type;
      if (type == "add") {
        this.dialogTitle = "新增卡套餐";
      } else {
        this.dialogTitle = "编辑卡套餐";
        this.addForm = row ? { ...row } : this.addForm;
      }
    },
    //点击确定
    handleSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.createTemplate();
          } else if (this.type === "edit") {
            this.updateTemplate();
          }
        }
      });
    },
    //新增套餐请求
    async createTemplate() {
      try {
        this.addLoading = true;
        let res = await createTemplate(this.addForm);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addLoading = false;
      }
    },
    //编辑套餐请求
    async updateTemplate() {
      try {
        this.addLoading = true;
        let res = await updateTemplate(this.addForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addLoading = false;
      }
    },
    handleClose() {
      this.addForm = {
        monthlyFee: "", //月租费
        monthlyFlow: "", //套餐每月流量
        simPackageName: "" //SIM卡套餐
      };
      this.$refs.addForm.resetFields();
      this.addVisible = false;
      this.queryList();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
